import SlateAuth from '@/api/auth/auth';

export default {
  install(VueInstance) {
    VueInstance.mixin({
      // Add component lifecycle hooks or properties.
      data() {
        return {
          slateAuth: SlateAuth,
        };
      },
    });
  },
};
