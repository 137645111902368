export default [
  {
    path: 'rfq-responses/',
    name: 'rfqResponseList',
    component: () => import(/* webpackChunkName: "rfqResponse" */ '@/modules/rfqs/rfq.response.list.page.vue'),
  },
  {
    path: 'rfq-responses/edit/:id/',
    alias: 'rfq-response/:id/',
    name: 'rfqResponseEdit',
    props: true,
    component: () => import(/* webpackChunkName: "rfqResponse" */ '@/modules/rfqs/rfq.response.edit.vue'),
  },
];
