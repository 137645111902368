export default [
  {
    path: 'vendor-estimate-responses/',
    name: 'vendorEstimateResponseList',
    component: () => import(/* webpackChunkName: "estimateResponse" */ '@/modules/estimates/vendorEstimateResponse.list.vue'),
  },
  {
    path: 'vendor-estimate-responses/edit/:id/',
    alias: 'estimates/vendor-estimate-response/:id/view/',
    name: 'vendorEstimateResponseEdit',
    props: true,
    component: () => import(/* webpackChunkName: "estimateResponse" */ '@/modules/estimates/vendorEstimateResponse.edit.vue'),
  },
];
