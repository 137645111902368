import Vue from 'vue';
import VueRouter from 'vue-router';
import SlateAuth from '@/api/auth/auth';

Vue.use(VueRouter);

let routes = [];

function getRoutes(r) {
  const filenames = r.keys();
  filenames.forEach((filename) => {
    routes = routes.concat(r(filename).default);
  });
}

getRoutes(require.context('@/modules/', true, /\.routes\.js$/));

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '',
    name: 'root',
    component: () => import(/* webpackChunkName: "slate" */ '@/modules/slate/root.page.vue'),
    children: [...routes],
  }],
});

router.beforeEach((to, from, next) => {
  if (!to.meta.anonymous && !SlateAuth.token) {
    next({
      name: 'login',
      query: {
        next: encodeURIComponent(to.fullPath),
      },
    });
  } else {
    next();
  }
});

export default router;
