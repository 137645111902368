export default [
  {
    path: '/',
    name: 'rootHome',
    component: () => import(/* webpackChunkName: "home" */ '@/modules/slate/home.page.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/modules/slate/home.page.vue'),
  },
];
