import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('@/locales', false, /[\w-]+\.json$/i);
  const messages = {};

  locales.keys().forEach((key) => {
    const matched = key.replace(/^\.\//, '').replace(/\.\w+$/, '');
    if (matched && matched.length > 1) {
      messages[matched] = locales(key);
    }
  });
  return messages;
}


let locale = 'en';
const navLang = navigator.language || navigator.userLanguage;
if (navLang) {
  locale = navLang.split('-')[0];
}
// TODO add check for users prefered language

export default new VueI18n({
  locale: locale || 'en',
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages: loadLocaleMessages(),
});
