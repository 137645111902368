<template>
<div class="slate-loading">
  <slate-icon icon="spinner fa-spin" />
    <span class="loading-text">{{ $t('APP.LOADING') }}</span>
</div>
</template>

<script>
import SlateIcon from '@/components/_baseIcon.vue';

export default {
  name: 'slateLoading',
  components: {
    SlateIcon,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.slate-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 2.4rem;
}

</style>
