/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue';

import * as Sentry from '@sentry/browser';
// import { Integrations } from '@sentry/tracing';

import buefy from './plugins/buefy';
import App from './App.vue';
import router from './plugins/router';
import store from './store';
import i18n from './plugins/i18n';

import '@/components/_globals';
import SlateAuth from './plugins/slate-auth';

import './plugins/fortawesome/fontawesome-pro/css/fontawesome.min.css';
import './plugins/fortawesome/fontawesome-pro/css/solid.min.css';
import '@/styles/main.scss';

Sentry.init({
  Vue,
  dsn: 'https://06bb8a1f551d4883820025d3592de7b9@o36595.ingest.sentry.io/5579071',
  logErrors: true,
});

window.sentry = Sentry;

Vue.config.productionTip = false;
Vue.use(SlateAuth);

new Vue({
  buefy,
  router,
  store,
  i18n,

  SlateAuth,
  render: (h) => h(App),
  watch: {
    'slateAuth.token': function watchToken(newVal, oldVal) {
      if (newVal === null && newVal !== oldVal) {
        this.$router.push({
          name: 'login',
          query: {
            next: encodeURIComponent(this.$router.currentRoute.fullPath),
          },
        });
      }
    },
  },
}).$mount('#app');
