export default [
  {
    path: '/login',
    name: 'login',
    meta: { anonymous: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/login-page.vue'),
  },
];
