<template>
  <article class="base-list"><slot></slot></article>
</template>

<script>
export default {
  name: 'listPage',
};
</script>

<style lang="scss" scoped>
  @import "@/styles/_variables.scss";

  .base-list {
    padding: 2.4rem;
    background-color: #FFF;
    background-color: rgba(255,255,255, 0.85);
    min-height: 100vh;
  }
</style>
