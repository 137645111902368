<template>
  <i class="fas fa-fw" :class="getClass()" />
</template>

<script>
export default {
  name: 'icon',
  props: {
    icon: String,
  },
  methods: {
    getClass() {
      return `fa-${this.icon}`;
    },
  },
};
</script>
