<template>
  <article class="page">
    <slate-loading v-if="loading" />
    <div v-else class="page-slots">
      <header v-if="!!$slots.header" class="page-header box"><slot name="header"></slot></header>
      <div v-if="!!$slots.default" class="page-main box"><slot/></div>
      <div v-if="!!$slots.bare" class="page-bare"><slot name="bare"/></div>
      <footer v-if="!!$slots.footer" class="page-footer box"><slot name="footer"></slot></footer>
    </div>
  </article>
</template>

<script>
export default {
  name: 'page',
  props: {
    loading: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  @import "@/styles/_variables.scss";

  .page {
    padding: 2.4rem;
    // background-color: rgba(255,255,255, 0.85);
    min-height: 100vh;
  }

  .page-slots > * {
    margin-bottom: 2.4rem;
  }

</style>
