import axios from 'axios';

import * as Sentry from '@sentry/browser';

const AUTH_URL = '/api/v1/auth';

class AuthToken {
  token = null;
  perms = {};

  constructor() {
    this.token = null;

    if (localStorage.getItem('slateToken')) {
      this.token = localStorage.getItem('slateToken');
    }

    axios.interceptors.request.use((config) => {
      if (this.token) {
        config.headers.authorization = this.token;
      }
      return config;
    });

    // Interceptor to check if the user has been logged out since the last
    // request has gone through.
    axios.interceptors.response.use((response) => response, (error) => {
      if (error.response && error.response.status === 401) {
        this.token = null;
        this.perms = {};
        localStorage.removeItem('slateToken');
      }
      return Promise.reject(error);
    });

    this.checkToken().catch(() => {
      // do nothing
    });
  }

  login(username, password) {
    this.username = username;
    return axios.post(`${AUTH_URL}/get-token/`, {
      username,
      password,
      remember: true,
    }).then((response) => {
      this.token = response.data.payload.token;
      this.perms = response.data.permissions;
      Sentry.setUser({ email: response.data.payload.contact_email });
      localStorage.setItem('slateToken', this.token);
      document.cookie = `Authorization=${this.token}`;
      return this;
    });
  }

  logout() {
    this.token = null;
    this.perms = {};
    localStorage.removeItem('slateToken');
  }

  checkToken() {
    if (!this.token) { return Promise.reject(new Error('Token not set yet')); }
    return new Promise((resolve, reject) => {
      axios.post(`${AUTH_URL}/check-token/`).then((response) => {
        this.token = response.data.payload.token;
        this.perms = response.data.permissions;
        Sentry.setUser({ email: response.data.payload.contact_email });
        document.cookie = `Authorization=${this.token}`;
        resolve(this);
      }).catch((e) => reject(e));
    });
  }
}


const token = new AuthToken();

export default token;
